<template>
  <div>
    <!-- Show the crops as tags -->
    <div
      v-for="crop in crops"
      :key="crop.id"
      class="bg-white text-infos d-inline-block w-auto mr-2 mb-2">
      {{ crop.name }}
    </div>

    <!-- message when no crop -->
    <div class="bg-white text-infos" v-if="crops.length == 0">
      {{ $t("crops.no-crop") }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {},
  computed: {
    ...mapGetters({
      crops: "tour/crops"
    }),
  }
};
</script>

<style lang="scss" scoped>
</style>
